import {
    IonContent,
    IonPage,
    IonButton,
    IonToolbar,
    IonTitle,
    IonHeader,
    useIonToast, 
    IonLoading,
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonRadioGroup,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonRadio,
    IonText,
    IonInput,
    IonButtons,
    IonIcon
  } from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { Link, useHistory, Route, Redirect } from 'react-router-dom';
import { loginUser, logoutUser } from '../firebaseConfig';
import { useAuth } from "../auth";

import { db } from '../firebaseConfig';
import { doc, getDoc, setDoc, serverTimestamp } from "firebase/firestore";

import { useForm } from "react-hook-form";
import { object, string, number } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { logOut } from 'ionicons/icons';


const Settings = () => <IonPage>Settings</IonPage>;

const Account: React.FC = () => {
    // const [email, setEmail] = useState('');
    // const [password, setPassword] = useState('');
    const [present, dismiss] = useIonToast();
    const [loading, setLoading] = useState<boolean>(false);
    const [certRequests, setCertRequests] = useState<any[]>([]);

    const { email, uid } = useAuth();

    const [bankCode, setBankCode] = useState<string>('');
    const [bankName, setBankName] = useState<string>('');
    const [accountNumber, setAccountNumber] = useState<string>('');
    const [accountName, setAccountName] = useState<string>('');
    const [mobile, setMobile] = useState<string>('');
    const [bankEmail, setBankEmail] = useState<string>(email || '');
    const [createdTime, setCreatedTime] = useState<Date>();
    

    // const certRequestsCollectionRef = collection(db, 'CertificateRequests');

    const history = useHistory();


    async function logout() {
        console.log('press logout');
        await logoutUser();
        history.replace('/login');
    }
    
    const validationSchema = object().shape({
        email: string().required("กรุณากรอกอีเมล").email(),
        accountNumber: string().required("กรุณากรอกเลขที่บัญชีธนาคารเฉพาะตัวเลข").min(10, "กรุณากรอกเลขที่บัญชีธนาคารเฉพาะตัวเลข").max(10, "กรุณากรอกเลขที่บัญชีธนาคารเฉพาะตัวเลข"),
        accountName: string().required("กรุณากรอกชื่อบัญชีธนาคาร").min(5).max(32),
        mobile: string().required(
            "กรุณากรอกเบอร์มือถือเฉพาะตัวเลข เช่น 0812345555"
        ).min(10, "กรุณากรอกเบอร์มือถือเฉพาะตัวเลข เช่น 0812345555").max(10, "กรุณากรอกเบอร์มือถือเฉพาะตัวเลข เช่น 0812345555"),
    });
    
    const {
        register,
        handleSubmit,
        formState: { isValid, errors },
        trigger,
        setValue
    } = useForm({
        mode: "all",
        // reValidateMode: 'onChange',
        resolver: yupResolver(validationSchema),
    });
    
    const onSave = async (data: any) => {
        console.log("Save bank account: ", data);

        if (bankCode) {

            setLoading(true);

            try {

                // Add a new document in collection "cities"
                await setDoc(doc(db, 'BankAccounts', String(uid)), {
                    accountNumber: accountNumber,
                    accountName: accountName,
                    mobile: mobile,
                    email: bankEmail,
                    bankName: bankName,
                    bankCode: bankCode,
                    createdTime: createdTime || serverTimestamp(),
                    updatedTime: serverTimestamp()

                });

                present('บันทึกข้อมูลสำเร็จ!', 3000);

            } catch(err) {
                
                present('บันทึกข้อมูลไม่สำเร็จ!', 3000);

            }
  
/* 
            const res = await registerUser(data.email, data.password, data.fullName, data.mobile);

            if (res) {
                present("ลงทะเบียนสำเร็จ", 2000);
            } else {
                present("ลงทะเบียนไม่สำเร็จ", 2000);
            }
 */
            setLoading(false);

        }
    };

    useEffect(() => {

        console.log('call initial useEffect');

        


        // certRequestsCollectionRef.get

        const getBankAccount = async () => {
            const docRef = doc(db, 'BankAccounts', String(uid));
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                console.log("Document data:", docSnap.data());

                setBankCode(docSnap.data().bankCode || '');
                setBankName(docSnap.data().bankName || '');
                setAccountNumber(docSnap.data().accountNumber);
                setAccountName(docSnap.data().accountName);
                setMobile(docSnap.data().mobile);
                setCreatedTime(docSnap.data().createdTime);

                setValue('accountNumber', docSnap.data().accountNumber, { shouldDirty: true })
                setValue('accountName', docSnap.data().accountName, { shouldDirty: true })
                setValue('mobile', docSnap.data().mobile, { shouldDirty: true })

            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }

        getBankAccount();
 
    }, [])

    return (
    <IonPage>
        <IonHeader>
            <IonToolbar>
                <IonTitle>บัญชีรับเงิน</IonTitle>
                <IonButtons slot="primary">
                    <IonButton onClick={logout} fill='clear'>
                        ออกจากระบบ
                        <IonIcon slot="end" icon={logOut} />

                    </IonButton>
                </IonButtons>
            </IonToolbar>
        </IonHeader>
        <IonLoading message='กำลังดำเนินการ...' duration={3} isOpen={loading} />
        <IonContent>
            <IonText color="primary">
                <h2 style={{textAlign: 'center', marginTop: 25}}>บัญชีธนาคารรับเงินค่าคอม</h2>
                <div style={{textAlign: 'center', marginTop: 9}}>(ค่าแนะนำโปรแกรม Leceipt)</div>
                <br/>
            </IonText>
            <form onSubmit={handleSubmit(onSave)}>
                <IonGrid>
                    {/* 
                    <IonRow>
                        <IonCol className="ion-align-self-center">
                        
                        </IonCol>
                    </IonRow>
                    <br/>
    */}
                    <IonRow>
                        <IonCol className="ion-align-self-center">
    {/* 
                        <Controller
                            control={control}
                            name="certPeriod"
                            render={({ 
                                field: { onChange, value },
                                // fieldState: { error } 
                            }) => (
    */}

                        <IonList>
                            <IonRadioGroup 
                                value={bankCode} 
    /*                                     
                                onIonChange={({ detail: { value } }) => {
                                    setPeriod(value);
                                    onChange(value);
                                }}
    */
                                onIonChange={e => {
                                    console.log('bank name:', e.detail.value);
                                    if (e.detail.value === 'kbank') {
                                        setBankName('ธนาคารกสิกรไทย');
                                        setBankCode(e.detail.value);

                                    } else if (e.detail.value === 'scb') {
                                        setBankName('ธนาคารไทยพาณิชย์');
                                        setBankCode(e.detail.value);
                                    } 
                                    
                                    // await trigger();

                                }}
                            >
                                <IonItemDivider>เลือกธนาคาร</IonItemDivider>
                                {/* 
                                <IonListHeader>
                                    <IonLabel>เลือกอายุการใช้งานใบรับรองอิเล็กทรอนิกส์</IonLabel>
                                </IonListHeader>
    */}
                                <IonItem>
                                    <IonLabel>ธนาคารกสิกรไทย</IonLabel>
                                    <IonRadio slot="start" value="kbank" />
                                </IonItem>

                                <IonItem>
                                    <IonLabel>ธนาคารไทยพาณิชย์</IonLabel>
                                    <IonRadio slot="start" value="scb" />
                                </IonItem>
                        
                            </IonRadioGroup>
        
                            
                        </IonList>

                        {!bankCode && (
                            // <div style={{textAlign: 'center', color: '#EB445A' }}>
                            <IonText color="danger">
                                <small>
                                    <span role="alert" id={`bankError`}>
                                        กรุณาเลือกธนาคาร
                                    </span>
                                </small>
                            </IonText>
                            // {/* </div> */}
                        )}  

                        </IonCol>
                    </IonRow>

                    <IonRow>
                        {/* <IonCol className="ion-align-self-center"> */}
                        <IonCol>

                            {/* <br/> */}
                            {/* <div>ข้อมูลบนใบรับรองอิเล็กทรอนิกส์</div> */}
                            {/* <div>ข้อมูลข้างล่างนี้ใช้สำหรับแสดง</div>
                            <div>บนใบรับรองอิเล็กทรอนิกส์ของท่าน</div>  */}
                            <br/>
                            <IonItem>
                                <IonLabel position="stacked">เลขที่บัญชี</IonLabel>
                                <IonInput 
                                    value={accountNumber} 
                                    {...register("accountNumber")}
                                    onIonChange={(e) => setAccountNumber(e.detail.value!)}
                                ></IonInput>
                                
                                {/* <p>{errors.nameTH?.message}</p> */}
                            </IonItem>
                            
                            {errors && errors.accountNumber && (
                                <IonText color="danger">
                                <small>
                                    <span role="alert" id={`accountNumberError`}>
                                    {errors.accountNumber?.message}
                                    </span>
                                </small>
                                </IonText>
                            )}

                            <IonItem>
                                <IonLabel position="stacked">ชื่อบัญชี</IonLabel>
                                <IonInput 
                                    value={accountName} 
                                    {...register("accountName")}
                                    onIonChange={(e) => setAccountName(e.detail.value!)}
                                ></IonInput>
                                
                                {/* <p>{errors.nameTH?.message}</p> */}
                            </IonItem>
                            
                            {errors && errors.accountName && (
                                <IonText color="danger">
                                <small>
                                    <span role="alert" id={`accountNameError`}>
                                    {errors.accountName?.message}
                                    </span>
                                </small>
                                </IonText>
                            )}

                            <IonItem>
                                <IonLabel position="stacked">เบอร์มือถือ</IonLabel>
                                <IonInput 
                                    value={mobile}
                                    {...register("mobile")}
                                    onIonChange={(e) => setMobile(e.detail.value!)}
                                ></IonInput>
                            </IonItem>
                            
                            {errors && errors.mobile && (
                                <IonText color="danger">
                                <small>
                                    <span role="alert" id={`mobileError`}>
                                    {errors.mobile?.message}
                                    </span>
                                </small>
                                </IonText>
                            )}
   
                            <IonItem>
                                <IonLabel position="stacked">อีเมล</IonLabel>
                                <IonInput 
                                    value={bankEmail}
                                    {...register("email")}
                                    onIonChange={(e) => setBankEmail(e.detail.value!)}
                                    // disabled                                    
                                ></IonInput>
                            </IonItem>
                            
                            {errors && errors.email && (
                                <IonText color="danger">
                                <small>
                                    <span role="alert" id={`emailError`}>
                                    {errors.email?.message}
                                    </span>
                                </small>
                                </IonText>
                            )}

                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div style={{textAlign: 'center'}}>
                    {/* 
                    <p style={{textAlign: 'center'}}>
                        Hello 
                    </p>               
    */}
                    <br/>
                    <br/>
                    <br/>
                    <IonButton 
                    
                        onClick={async () => { 
                            // present('บันทึกข้อมูลสำเร็จ!', 3000);
                            console.log('errors:', errors);

                            // const result = await trigger('mobile', { shouldFocus: true });
                            // console.log('trigger result:', result);
                        }}
                        style={{width: 150}}
                        type='submit'

                    >บันทึก</IonButton>
                    <br/>
                    <br/>
                  {/*  <br/>
                    <br/>
                     <br/> 
                     <br/> 
                    <IonButton onClick={logout} color="light">ออกจากระบบ</IonButton>
                    */}
                </div>
            </form>    
        </IonContent>
      </IonPage>
  );
};

export default Account;
