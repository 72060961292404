import {
    IonContent,
    IonPage,
    IonText,
    IonInput,
    IonButton,
    IonCheckbox,
    IonItem,
    IonLabel,
    IonToolbar,
    IonTitle,
    IonHeader,
    IonToast,
    useIonToast, 
    IonLoading,
    IonTabs, 
    IonTabBar, 
    IonTabButton, 
    IonIcon, 
    IonBadge,
    IonRouterOutlet,
    IonCard,
    IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent
  } from "@ionic/react";
import React, { useState, useEffect } from 'react';
import { Link, useHistory, Route, Redirect } from 'react-router-dom';
import { loginUser, logoutUser } from '../firebaseConfig';
import { home, personCircle, list } from 'ionicons/icons';
import { IonReactRouter } from '@ionic/react-router';
import { db } from '../firebaseConfig';
import { collection, getDocs, query, where, onSnapshot, doc } from "firebase/firestore";
import { useAuth } from "../auth";

import moment from 'moment-timezone';
import { format } from "path";

// const Settings = () => <IonPage>Settings</IonPage>;

import './CertificateRequests.css';



const CertificateRequests: React.FC = () => {
    // const [email, setEmail] = useState('');
    // const [password, setPassword] = useState('');
    const [present, dismiss] = useIonToast();
    const [loading, setLoading] = useState<boolean>(false);
    const [certRequests, setCertRequests] = useState<any[]>([]);

    // const username = useSelector((state: any) => state.user.email);


    const history = useHistory();
    const { email, uid } = useAuth();
    // const referralCode = uid ? uid.slice(-7) : '';
    const referralCode = uid ? uid.slice(0, 7) : '';

    async function logout() {
        await logoutUser();
        history.replace('/');
    }

    function formatDateTime(date: number) {
    
        return moment.utc(date).tz("Asia/Bangkok").format("DD/MM/YYYY HH:mm:ss น.");

    }
 
    useEffect(() => {

        console.log('call initial useEffect');
      /*     
        const certRequestsCollectionRef = collection(db, 'CertificateRequests');
        const q = query(certRequestsCollectionRef, where('referralCode', '==', referralCode));

        // certRequestsCollectionRef.get

        const getCertRequests = async () => {
         
            const querySnapshot = await getDocs(q);
            console.log(querySnapshot);

            const data = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
            console.log(data);

            setCertRequests(data);

            
        }

        getCertRequests();
 */
        const q = query(collection(db, 'CertificateRequests'), where('referralCode', '==', referralCode));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            console.log(querySnapshot);

            const data = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
            console.log(data);

            setCertRequests(data);
        });
 
    }, [])

    return (
    <IonPage>
        <IonHeader>
            <IonToolbar>
                <IonTitle>ประวัติรายการกรอกข้อมูลในลิงค์</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonLoading message='กำลังดำเนินการ...' duration={0} isOpen={loading} />
        <IonContent>

            {certRequests.map((doc) => 
                <IonCard key={doc.id}>
                    <IonCardHeader>
                        <IonCardTitle style={{ color: '#488AFF' }}>{doc.nameTH}</IonCardTitle>
                        <IonCardSubtitle style={{ color: '#000000' }}><h4>สถานะ: {doc.status === 'successful' ? 'สำเร็จ' : doc.status === 'expired' ? 'หมดอายุ' : 'รอส่งเอกสาร'}</h4></IonCardSubtitle>
                        <IonCardSubtitle style={{ color: '#000000' }}><h6>วันที่โอนเงิน: {doc?.transferTime?.seconds ? formatDateTime(doc?.transferTime?.seconds * 1000) : ''}</h6></IonCardSubtitle>
                        <IonCardSubtitle style={{ color: '#000000' }}><h6>จำนวนเงิน: {doc.transferAmount ?  doc.transferAmount + ' บาท' : ''}</h6></IonCardSubtitle>
                    </IonCardHeader>

                    <IonCardContent>

                        {/* {doc.type} {doc.nameTH} */}
                        {/* <br/> */}

                        <IonCardSubtitle>หมายเหตุ: {doc?.note}</IonCardSubtitle>
                        {/* <br/> */}
                        {/* <IonCardSubtitle>อัปเดทล่าสุด: {new Date(doc.updatedTime.seconds * 1000).toLocaleDateString("th-TH")}</IonCardSubtitle> */}
                        <IonCardSubtitle>อัปเดทล่าสุด: {formatDateTime(doc.updatedTime.seconds * 1000)}</IonCardSubtitle>
                    </IonCardContent>
                </IonCard>
            
            )}
            {/* 
            <IonCard>
                
                <IonCardHeader>
                    <IonCardTitle style={{ color: 'green' }}>ค่าคอม 1,050 บาท</IonCardTitle>
                    <IonCardSubtitle style={{ color: 'blue' }}><h4>สถานะ: รอส่งเอกสาร</h4></IonCardSubtitle>
                    <IonCardSubtitle style={{ color: 'blue' }}><h6>วันที่โอนเงิน: (รับเงินเมื่อติดตั้งใบรับรองฯ)</h6></IonCardSubtitle>
                </IonCardHeader>

                <IonCardContent>
                    บริษัท ขายดีมากมาก จำกัด
                    <br/>
                    <br/>
                    <IonCardSubtitle>อัปเดทล่าสุด: 20/07/2565</IonCardSubtitle>
                </IonCardContent>
            </IonCard>
  */}
        </IonContent>
       
        
      </IonPage>
  );
};

export default CertificateRequests;
