import {
    IonContent,
    IonPage,
    IonText,
    IonInput,
    IonButton,
    IonCheckbox,
    IonItem,
    IonLabel,
    IonToolbar,
    IonTitle,
    IonHeader,
    IonToast,
    useIonToast, 
    IonLoading,
    IonTabs, 
    IonTabBar, 
    IonTabButton, 
    IonIcon, 
    IonBadge,
    IonRouterOutlet
  } from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { Link, useHistory, Route, Redirect } from 'react-router-dom';
import { loginUser, logoutUser } from './firebaseConfig';
import { home, personCircle, list } from 'ionicons/icons';
import { useAuth } from './auth';

import AffiliateLink from './pages/AffiliateLink';
import CertificateRequests from './pages/CertificateRequests';
import Account from './pages/Account';

const Settings = () => <IonPage>Settings</IonPage>;

const AppTabs: React.FC = () => {
    // const [email, setEmail] = useState('');
    // const [password, setPassword] = useState('');
    const [present, dismiss] = useIonToast();
    const [loading, setLoading] = useState<boolean>(false);
    const [certRequests, setCertRequests] = useState<any[]>([]);
    
    const history = useHistory();

    const { loggedIn } = useAuth();

    async function logout() {
        console.log('press logout');
        await logoutUser();
        history.replace('/login');
    }
  /* 
    useEffect(() => {

        console.log('call initial useEffect');
      
        const getCertRequests = async () => {
            // const data = await getDocs(certRequestsCollectionRef);
            // console.log(data);

            // setCertRequests(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        }

        getCertRequests();

    }, [])
 */
    console.log('Apptabs loggedIn:', loggedIn);
    if (!loggedIn) {
        return <Redirect to='/login' />;
    }

    return (
    
        <IonTabs>
            <IonRouterOutlet>
                <Route exact path='/my/link'>
                    <AffiliateLink />
                </Route>
                <Route exact path='/my/list'>
                    <CertificateRequests />
                </Route>
                <Route exact path='/my/account'>
                    <Account />
                </Route>
                {/* <Redirect from='/' to='/dashboard' /> */}
            </IonRouterOutlet>
            <IonTabBar slot='bottom'>
                <IonTabButton tab='link' href='/my/link'>
                    <IonIcon icon={home} />
                    <IonLabel>ลิงค์แนะนำ</IonLabel>
                </IonTabButton>

                <IonTabButton tab='list' href='/my/list'>
                    <IonIcon icon={list} />
                    <IonLabel>ประวัติรายการ</IonLabel>
                </IonTabButton>

                <IonTabButton tab='account' href='/my/account'>
                    <IonIcon icon={personCircle} />
                    <IonLabel>บัญชีรับเงิน</IonLabel>
                </IonTabButton>
            </IonTabBar>
        </IonTabs>
            
    );
};

export default AppTabs;
