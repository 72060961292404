import {
    IonContent,
    IonPage,
    IonButton,
    IonToolbar,
    IonTitle,
    IonHeader,
    useIonToast, 
    IonLoading,
    IonIcon
  } from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { Link, useHistory, Route, Redirect } from 'react-router-dom';
import { loginUser, logoutUser } from '../firebaseConfig';
import { useAuth } from "../auth";
import { copy } from 'ionicons/icons'
import { Clipboard } from '@capacitor/clipboard';

import { doc, onSnapshot } from "firebase/firestore";
import { db } from '../firebaseConfig';

// import './AffiliateLink.css';

// const Settings = () => <IonPage>Settings</IonPage>;

// const APP_VERSION = process.env.REACT_APP_APP_VERSION;


const Update: React.FC = () => {
    // const [email, setEmail] = useState('');
    // const [password, setPassword] = useState('');
    const [present, dismiss] = useIonToast();
    const [loading, setLoading] = useState<boolean>(false);
    const [commission, setCommission] = useState<number>(0);
    const [version, setVersion] = useState<string>('');

    // const certRequestsCollectionRef = collection(db, 'CertificateRequests');

    const history = useHistory();

    const { email, uid } = useAuth();

    // const code = uid ? uid.slice(-7) : '';
    const code = uid ? uid.slice(0, 7) : '';

    const APP_VERSION = process.env.REACT_APP_APP_VERSION;

    async function logout() {
        console.log('press logout');
        await logoutUser();
        history.replace('/login');
    }

    const writeToClipboard = async (linkText: string) => {
        await Clipboard.write({
          string: linkText
        });
    };

    useEffect(() => {

        console.log('call initial useEffect');
      /* 
        const getGlobalConfig = async () => {
            // const data = await getDocs(certRequestsCollectionRef);
            // console.log(data);

            // setCertRequests(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));

            
        }

        getGlobalConfig();
 */
        const unsub = onSnapshot(doc(db, "Configurations", "global"), (doc) => {
            console.log("Global config data: ", doc.data());

            setCommission(doc.data()?.commission || 0);
            setVersion(doc.data()?.version || 0);

            
        });

    }, [])
  

    return (
    <IonPage>
        <IonHeader>
            <IonToolbar>
                <IonTitle>Leceipt Affiliate</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonLoading message='กำลังดำเนินการ...' duration={0} isOpen={loading} />
        <IonContent>
            <div style={{textAlign: 'center'}}>
                <p style={{textAlign: 'center'}}>
                    เวอร์ชั่นที่ใช้งานปัจจุบัน: {APP_VERSION}
                </p>
                <br/>
                
                    
                <h3>กดปุ่มอัปเดท</h3>
                <h4>เพื่อติดตั้ง APP เวอร์ชั่นล่าสุด</h4>
                <p style={{textAlign: 'center'}}>
                    เวอร์ชั่นล่าสุด: {version}
                </p>
                <br/>
                <br/>
                <IonButton
                    href={"https://leceipt.com/d/leceipt-affiliate.apk"}
                    style={{ width: 150 }}
                >
                    อัปเดท 
                    {/* <IonIcon icon={copy} /> */}
                </IonButton>
                <br/>
                <br/>
                <br/>
                
                
                <br/>
                <br/>

                {/* <IonButton onClick={logout}>ออกจากระบบ</IonButton> */}
            </div>
            
        </IonContent>
      </IonPage>
  );
};

export default Update;
