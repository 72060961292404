import {
    IonContent,
    IonPage,
    IonText,
    IonInput,
    IonButton,
    IonCheckbox,
    IonItem,
    IonLabel,
    IonToolbar,
    IonTitle,
    IonHeader,
    IonGrid,
    IonRow,
    IonCol,
    IonToast,
    useIonToast, 
    IonLoading,
    IonTabs, 
    IonTabBar, 
    IonTabButton, 
    IonIcon, 
    IonBadge,
    IonRouterOutlet,
    IonCard,
    IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, 
  } from "@ionic/react";
import React, { useState, useEffect } from 'react';
import { Link, useHistory, Route, Redirect } from 'react-router-dom';

const TermsAndConditions: React.FC = () => {

    const history = useHistory();


    return (
    <IonPage>
        <IonHeader>
            <IonToolbar>
                <IonTitle>Leceipt Affiliate</IonTitle>
            </IonToolbar>
        </IonHeader>
        {/* <IonLoading message='กำลังดำเนินการ...' duration={0} isOpen={loading} /> */}
        <IonContent>
            <div className="ion-padding">
                <IonText color="primary">
                    <h2 style={{ textAlign: 'center', marginTop: 5 }}>เงื่อนไขและข้อตกลงการใช้งาน</h2>
                </IonText>

                <IonText>
                    <p>บริษัท ฟรีเวชั่น จำกัด (บริษัท) ขอขอบพระคุณท่านที่ใช้งานระบบ Leceipt Affiliate บริษัทขอแจ้งข้อกำหนดและเงื่อนไข เพื่อเป็นข้อตกลงและสร้างความเข้าใจเกี่ยวกับการใช้บริการระบบ Leceipt Affiliate ดังนี้</p>
                    <p>1. บริษัทจะดูแลระบบการทำงานและระบบเครือข่ายของบริษัทให้สามารถใช้งานได้อยู่เสมออย่างไรก็ตาม อาจมีข้อบกพร่องในการใช้งานในบางครั้งและอาจอยู่นอกเหนือการควบคุมของบริษัท ซึ่งบริษัทจะเร่งดำเนินการแก้ไขเพื่อให้สามารถใช้งานได้เป็นปกติโดยเร็วที่สุด</p>
                    <p>2. ท่านสามารถใช้บริการระบบ Leceipt Affiliate ได้ผ่านทางเครื่องคอมพิวเตอร์ โทรศัพท์มือถือ สมาร์ทโฟน และแท็บเล็ต เป็นต้น</p>
                    <p>3. การใช้บริการของท่านไม่ได้ทำให้ท่านเป็นเจ้าของสิทธิในทรัพย์สินทางปัญญาใดในบริการที่ท่านเข้าถึง ท่านไม่สามารถนำรูปแบบการให้บริการไปเผยแพร่ ทำซ้ำ หรือละเมิดสิทธิทางทรัพย์สินทางปัญญาใดได้ เว้นแต่ได้รับอนุญาตจากเจ้าของสิทธิก่อน</p>
                    <p>4. ระยะเวลาการเป็นสมาชิกจะมีผลอยู่ตลอดไปจนกว่าท่านจะบอกเลิกการเป็นสมาชิก หรือสิ้นสุดระยะเวลาการให้บริการตามที่บริษัทกำหนด หรือบริษัทยกเลิก อันเนื่องจากผู้ใช้บริการไม่ปฏิบัติหรือปฏิบัติผิดข้อกำหนดของบริษัท</p>
                    <p>5. บริษัทขอสงวนสิทธิ์ในการเปลี่ยนแปลงอัตราค่าคอมมิสชั่น โดยไม่ต้องแจ้งให้ทราบล่วงหน้า</p>
                    <p>6. บริษัทขอสงวนสิทธิ์ในการยกเลิกหรือเปลี่ยนแปลงระบบ Leceipt Affiliate โดยไม่ต้องแจ้งให้ทราบล่วงหน้า</p>
                    <p>7. บริษัทมีสิทธิ์บอกกล่าว ให้แก้ไขรูปแบบการโฆษณาประชาสัมพันธ์ลิงค์แนะนำ ท่านจะยินยอมแก้ไขและดำเนินการตามที่บริษัทได้แจ้งไป</p>
                    {/* <p>7. การใช้งานระบบ Leceipt Affiliate ไม่ได้ให้สิทธิ์ท่านในการเป็นหุ้นส่วนบริษัท ท่านจะต้องไม่นำชื่อบริษัท หรือเครื่องหมายการค้าของบริษัทไปอ้างถึงในการทำธุรกรรมใด ๆ</p> */}
                    <br/>
                </IonText>

                <IonGrid>
                    <IonRow class="ion-align-items-center">
                    <IonCol size="12"  class="ion-text-center">
                        <IonButton
                            // expand="full"
                            onClick={() => { 
                                history.goBack();                    
                            }}
                            style={{width: 150}}
                        >
                            กลับ
                        </IonButton>
                    </IonCol>
                    </IonRow>
                </IonGrid>

            </div>
        </IonContent>
        
        
      </IonPage>
  );
};

export default TermsAndConditions;
