import { Redirect, Route, Switch } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact,  
    IonLoading} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import React from 'react';

import Home from './pages/Home';
import Register from './pages/Register';
import Login from './pages/Login';
import CertificateRequestForm from './pages/CertificateRequestForm';
import CertificateRequestsSubmitted from './pages/CertificateRequestsSubmited';
import TermsAndConditions from './pages/TermsAndConditions';
import Update from './pages/Update';
import AppTabs from './AppTabs';

import './App.css';


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import { AuthContext, useAuthInit } from './auth';

setupIonicReact();

const RoutingSystem: React.FC = () => {
    return (
        <IonReactRouter>
            <IonRouterOutlet>

                <Switch>
                    <Route exact path="/home">
                        <Home />
                    </Route>
                    <Route exact path="/register">
                        <Register />
                    </Route>
                    <Route exact path="/login">
                        <Login />
                    </Route>
                    <Route exact path="/forms/certificate-requests">
                        <CertificateRequestForm />
                    </Route>
                    <Route exact path="/forms/certificate-requests/submitted">
                        <CertificateRequestsSubmitted />
                    </Route>
                    <Route exact path="/terms-conditions">
                        <TermsAndConditions />
                    </Route>
                    <Route exact path="/update">
                        <Update />
                    </Route>
                    <Route path="/my">
                        <AppTabs />
                    </Route>
                    <Route exact path="/">
                        <Redirect to="/my/link" />
                    </Route>
                </Switch>
 {/*               
                <Route path="/">
                    <CertificateRequestForm />
                </Route>
                <Route exact path="/forms/certificate-requests/submitted">
                    <CertificateRequestsSubmitted />
                </Route>
 */} 


            </IonRouterOutlet>            
        </IonReactRouter>
    );

}

const App: React.FC = () => {

    const { loading, auth } = useAuthInit();

    console.log(`rendering App with auth:`, auth);
    if (loading) {
        return <IonLoading isOpen />;
    }
    return(
        <IonApp>
            <AuthContext.Provider value={auth || { loggedIn: false }}>
                <RoutingSystem />
            </AuthContext.Provider>
        </IonApp>
    );
};

export default App;
