import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Home.css';
import { Link, useHistory } from 'react-router-dom';

const Home: React.FC = () => {
    const history = useHistory();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Leceipt Affliate</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large" style={{textAlign: "center"}}>Leceipt Affliate</IonTitle>
          </IonToolbar>
        </IonHeader>
        <br/>
        <br/>
        <br/>
        <IonButton onClick={() => { history.replace('/login') }} expand="block">เข้าสู่ระบบ</IonButton>
        <br/>
        <IonButton onClick={() => { history.replace('/register') }} expand="block" color="secondary">ลงทะเบียน</IonButton>
      </IonContent>
    </IonPage>
  );
};

export default Home;
