import {
    IonContent,
    IonPage,
    IonButton,
    IonToolbar,
    IonTitle,
    IonHeader,
    useIonToast, 
    IonLoading,
    IonIcon
  } from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { Link, useHistory, Route, Redirect } from 'react-router-dom';
import { loginUser, logoutUser } from '../firebaseConfig';
import { useAuth } from "../auth";
import { copy } from 'ionicons/icons'
import { Clipboard } from '@capacitor/clipboard';

import { doc, onSnapshot } from "firebase/firestore";
import { db } from '../firebaseConfig';

import './AffiliateLink.css';

// const Settings = () => <IonPage>Settings</IonPage>;

// const APP_VERSION = process.env.REACT_APP_APP_VERSION;


const AffiliateLink: React.FC = () => {
    // const [email, setEmail] = useState('');
    // const [password, setPassword] = useState('');
    const [present, dismiss] = useIonToast();
    const [loading, setLoading] = useState<boolean>(false);
    const [commission, setCommission] = useState<number>(0);
    const [version, setVersion] = useState<string>('');

    // const certRequestsCollectionRef = collection(db, 'CertificateRequests');

    const history = useHistory();

    const { email, uid } = useAuth();

    // const code = uid ? uid.slice(-7) : '';
    const code = uid ? uid.slice(0, 7) : '';

    const APP_VERSION = process.env.REACT_APP_APP_VERSION;

    async function logout() {
        console.log('press logout');
        await logoutUser();
        history.replace('/login');
    }

    const writeToClipboard = async (linkText: string) => {
        await Clipboard.write({
          string: linkText
        });
    };
  
    useEffect(() => {

        console.log('call initial useEffect');
      /* 
        const getGlobalConfig = async () => {
            // const data = await getDocs(certRequestsCollectionRef);
            // console.log(data);

            // setCertRequests(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));

            
        }

        getGlobalConfig();
 */
        const unsub = onSnapshot(doc(db, "Configurations", "global"), (doc) => {
            console.log("Global config data: ", doc.data());

            setCommission(doc.data()?.commission || 0);
            setVersion(doc.data()?.version || 0);

            if (doc.data()?.version !== APP_VERSION) {
                history.push('/update');  
            }
        });

    }, [])

    return (
    <IonPage>
        <IonHeader>
            <IonToolbar>
                <IonTitle>Leceipt Affiliate</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonLoading message='กำลังดำเนินการ...' duration={0} isOpen={loading} />
        <IonContent>
            <div style={{textAlign: 'center'}}>
                <p style={{textAlign: 'center'}}>
                    Hello {email}
                </p>
                <br/>
                
                    <h4>ลิงค์แนะนำ (Affiliate):</h4>
               
                <a href={"https://leceipt.to/" + code} target="_blank"><h3>https://leceipt.to/{code}</h3></a>
                <h3>Commission: {commission}%</h3>
                <IonButton
                    // expand="full"
                    onClick={() => { 
                        // navigator.clipboard.writeText("https://leceipt.to/" + code);

                        writeToClipboard("https://leceipt.to/" + code);
                        present('Copy Link สำเร็จ! ส่งลิงค์ แล้วรอรับค่าคอมได้เลย!', 3000);
                    
                    }}
                >
                    Copy Link 
                    {/* <IonIcon icon={copy} /> */}
                </IonButton>
                <br/>
                <br/>
                <br/>
                <p>หากมีบริษัทสมัครใช้งานโปรแกรม Leceipt ผ่านลิงค์นี้</p>
                <p><b>ท่านจะได้รับเงินค่าแนะนำ {commission}%</b></p>
                <p>จากค่าบริการติดตั้งโปรแกรมครั้งแรก</p>
                <p>โดยจะ<u>ได้ค่าคอมตั้งแต่บริษัทที่ 4 เป็นต้นไป</u></p>
                <br/>
{/* 
                <p>*** บริษัทขอสงวนสิทธิ์ในการเปลี่ยนแปลงอัตรา Commission โดยไม่ต้องแจ้งให้ทราบล่วงหน้า ***</p>
                <br/>
                 */}
                <p>สอบถามเพิ่มเติม Line ID: @Leceipt</p>
                <p>เวลาทำการ 8.30 - 17.30 น.</p>
                <br/>
                <br/>
                <br/>
                <br/>
                <a 
                    // target="_blank" 
                    className="otherPage"
                    onClick={() => { 
                        history.push('/terms-conditions');                    
                    }}
                >เงื่อนไขและข้อตกลงการใช้งาน</a>
                {/* <small>เงื่อนไขและข้อตกลงการใช้งาน</small> */}
                <br/>
                <br/>
                <small>โปรแกรมเวอร์ชั่น: {APP_VERSION}</small>
                <br/>
                <br/>

                {/* <IonButton onClick={logout}>ออกจากระบบ</IonButton> */}
            </div>
            
        </IonContent>
      </IonPage>
  );
};

export default AffiliateLink;
