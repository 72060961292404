import {
    IonContent,
    IonPage,
    IonButton,
    IonToolbar,
    IonTitle,
    IonHeader,
    useIonToast, 
    IonLoading,
    IonText,
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonRadioGroup,
    IonListHeader,
    IonLabel,
    IonItem,
    IonRadio,
    IonItemDivider,
    IonInput,
    IonFooter,
    IonNote

  } from '@ionic/react';
import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory, Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { loginUser, logoutUser } from '../firebaseConfig';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { db } from '../firebaseConfig';
import { collection, addDoc, serverTimestamp } from "firebase/firestore";

import './CertificateRequestForm.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

// import 'swiper/swiper.min.css';
import '@ionic/react/css/ionic-swiper.css';

const schema = yup.object({

    nameTH: yup.string().required('กรุณากรอกชื่อ บริษัท/นิติบุคคล (ภาษาไทย)'),
    nameEN: yup.string().required('กรุณากรอกชื่อ บริษัท/นิติบุคคล (ภาษาอังกฤษ)'),
    taxId: yup.string().required('กรุณากรอกเลขทะเบียนนิติบุคคล 13 หลัก').min(13).max(13),
    email: yup.string().required('กรุณากรอกอีเมลสำหรับแสดงบนใบรับรองฯ').email(),
    // period: yup.number().positive('กรุณาเลือกอายุการใช้งานใบรับรองอิเล็กทรอนิกส์').integer('กรุณาเลือกอายุการใช้งานใบรับรองอิเล็กทรอนิกส์').required('กรุณาเลือกอายุการใช้งานใบรับรองอิเล็กทรอนิกส์'),
    // period: yup.string().required('กรุณาเลือกอายุการใช้งานใบรับรองอิเล็กทรอนิกส์'),
    address: yup.string().required('กรุณากรอกที่อยู่ตามหนังสือรับรองนิติบุคคล'),
    phone: yup.string().required('กรุณากรอกเบอร์โทรติดต่อ'),
    mobile: yup.string().required('กรุณากรอกเบอร์มือถือ'),
    firstName: yup.string().required('กรุณากรอกชื่อ'),
    lastName: yup.string().required('กรุณากรอกนามสกุล'),
    contactEmail: yup.string().required('กรุณากรอกอีเมล').email(),
    contactMobile: yup.string().required('กรุณากรอกเบอร์โทรติดต่อ'),
    
    // age: yup.number().positive().integer().required(),
  }).required();
/* 
interface CertificateRequestFormProps
    extends RouteComponentProps<{
        code: string;
}> {}
 */
const CertificateRequestForm: React.FC = () => {
// const CertificateRequestForm: React.FC<CertificateRequestFormProps> = ({ match }) => {
    // const [email, setEmail] = useState('');
    // const [password, setPassword] = useState('');
    const [present, dismiss] = useIonToast();
    const [loading, setLoading] = useState<boolean>(false);
    const [certRequests, setCertRequests] = useState<any[]>([]);
    const [mySwiper, setMySwiper] = useState<any>({});
    // const { register, handleSubmit, formState: { errors } } = useForm();
    const { register, handleSubmit, formState:{ isValid, errors }, control } = useForm({
        mode: 'all',
        // reValidateMode: 'onChange',
        resolver: yupResolver(schema)
    });

    const [page, setPage] = useState<number>(0);
    const [period, setPeriod] = useState<number>(0);
    const [nameTH, setNameTH] = useState<string>('');
    const [nameEN, setNameEN] = useState<string>('');
    const [taxId, setTaxId] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [address, setAddress] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [mobile, setMobile] = useState<string>('');
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [contactEmail, setContactEmail] = useState<string>('');
    const [contactMobile, setContactMobile] = useState<string>('');

    const [nextText, setNextText] = useState<string>('ต่อไป');

    // const certRequestsCollectionRef = collection(db, 'CertificateRequests');

    const history = useHistory();
    // const swiper = useSwiper();

    const fee = 3500;
    // const referralCode = match.params.code || '';
    const referralCode = window.location.pathname.replace('/','');
/* 
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop: any) => searchParams.get(prop),
      });
      // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
    let referralEmail = params.get('e'); // "some_value"
 */
    const urlParams = new URLSearchParams(window.location.search);
    const referralEmail = urlParams.get('e') || '';

    const contentRef = useRef<HTMLIonContentElement | null>(null);
    const scrollToTop= () => {
        contentRef.current && contentRef.current.scrollToTop();
    };

    async function logout() {
        console.log('press logout');
        await logoutUser();
        history.replace('/home');
    }
  /* 
    useEffect(() => {

        console.log('call initial useEffect');
      
        const getCertRequests = async () => {
            // const data = await getDocs(certRequestsCollectionRef);
            // console.log(data);

            // setCertRequests(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        }

        getCertRequests();

    }, [])
 */

    const submitForm = async (data: any) => {
        console.log("creating a new certificate request with: ", data);

        
  /* 
        setLoading(true);
        
        const res = await registerUser(data.email, data.password);
  
        if (res) {
          present('ลงทะเบียนสำเร็จ', 2000);
        } else {
          present('ลงทะเบียนไม่สำเร็จ', 2000);
        }
  
        setLoading(false);
   */
    };

    useEffect(() => {
        // register("nameTH", { required: true });
        // register("nameEN");
      }, []);

    return (
    <IonPage>
        <IonHeader>
            <IonToolbar>
                <IonTitle>Leceipt</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonLoading message='กำลังดำเนินการ...' duration={0} isOpen={loading} />
        <IonContent  ref={contentRef} scrollEvents={true}>
            <IonText color="primary">
                <h2 style={{textAlign: 'center', marginTop: 9}}>แบบฟอร์มขอใบรับรองอิเล็กทรอนิกส์</h2>
                <div style={{textAlign: 'center', marginTop: 9}}>(e-Tax Invoice & e-Receipt)</div>
                <br/>
            </IonText>
            <form onSubmit={handleSubmit(submitForm)}>
            <Swiper
                spaceBetween={50}
                // slidesPerView={3}
                onInit={(ev) => {
                    setMySwiper(ev)
                }}
                // direction='vertical'
                onSlideChange={() => {
                    // handleSubmit(submitForm);
                    // console.log('formState:', errors);
                    console.log('slide change')
                }}
                onSwiper={(swiper) => console.log(swiper)}
                style={{marginBottom: 60}}
                >
 {/* 
            <Swiper modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={50}
      slidesPerView={3}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log('slide change')}
      >
     */}    

                <SwiperSlide>
                    <IonGrid>
                        {/* 
                        <IonRow>
                            <IonCol className="ion-align-self-center">
                            
                            </IonCol>
                        </IonRow>
                        <br/>
*/}
                        <IonRow>
                            <IonCol className="ion-align-self-center">
{/* 
                            <Controller
                                control={control}
                                name="certPeriod"
                                render={({ 
                                    field: { onChange, value },
                                    // fieldState: { error } 
                                }) => (
 */}

                            <IonList>
                                <IonRadioGroup 
                                    value={String(period)} 
/*                                     
                                    onIonChange={({ detail: { value } }) => {
                                        setPeriod(value);
                                        onChange(value);
                                    }}
*/
                                    onIonChange={e => {
                                        setPeriod(e.detail.value*1);
                                        console.log('period:', e.detail.value);
                                    }}
                                >
                                    <IonItemDivider>เลือกอายุการใช้งานใบรับรองอิเล็กทรอนิกส์</IonItemDivider>
                                    {/* 
                                    <IonListHeader>
                                        <IonLabel>เลือกอายุการใช้งานใบรับรองอิเล็กทรอนิกส์</IonLabel>
                                    </IonListHeader>
 */}
                                    <IonItem>
                                        <IonLabel>1 ปี (ราคา 3,000 บาท)</IonLabel>
                                        <IonRadio slot="start" value="1" />
                                    </IonItem>

                                    <IonItem>
                                        <IonLabel>2 ปี (ราคา 5,000 บาท)</IonLabel>
                                        <IonRadio slot="start" value="2" />
                                    </IonItem>
                           
                                </IonRadioGroup>
         
                                {(period) 
                                    ?
                                        <div>
                                            {/* <br/> */}
                                            {/* <IonItemDivider>รวม (ค่าบริการ 3,500) </IonItemDivider> */}
                                            {/* <IonItemDivider>รวมค่าบริการ 3,500 เป็นเงิน {(period === 1) ? 3000 + fee : (period === 2) ? 5000 + fee : 0 } บาท</IonItemDivider>  */}
                                            
                                <IonRadioGroup value="1">
                                    <IonItemDivider>ค่าบริการ 3,500 บาท</IonItemDivider>
                                    {/* 
                                    <IonListHeader>
                                        <IonLabel>เลือกอายุการใช้งานใบรับรองอิเล็กทรอนิกส์</IonLabel>
                                    </IonListHeader>
*/}
                                    <IonItem>
                                        <IonLabel>ขอใบรับรองอิเล็กทรอนิกส์</IonLabel>
                                        <IonRadio slot="start" value="1"/>
                                    </IonItem>

                                    <IonItem>
                                        <IonLabel>ติดตั้งใบรับรองอิเล็กทรอนิกส์</IonLabel>
                                        <IonRadio slot="start" value="1"/>
                                    </IonItem>
                                </IonRadioGroup>

                                            <IonItemDivider>
                                                <IonText color="primary">
                                                    รวมเป็นเงิน {(period === 1) ? '6,500' : (period === 2) ? '8,500' : 0 } บาท <small>(ยังไม่รวม Vat)</small>
                                                </IonText>
                                            </IonItemDivider> 
                                            
                                        </div> 
                                    : 
                                        <div>
{/* 
                                            <IonItemDivider>
                                                <IonText color="primary">
                                                    กรุณาเลือกอายุใบรับรองอิเล็กทรอนิกส์
                                                </IonText>
                                            </IonItemDivider> 
                               */}              
                                        </div>
                                }
                            </IonList>
{/*                             
                            )}
                            />

                            {errors && errors.certPeriod && (
                                <IonText color="danger">
                                <small>
                                    <span role="alert" id={`certPeriodError`}>
                                    {errors.certPeriod?.message}
                                    </span>
                                </small>
                                </IonText>
                            )}
 */}
                            {!period && (
                                <IonText color="danger">
                                <small>
                                    <span role="alert" id={`certPeriodError`}>
                                    กรุณาเลือกอายุการใช้งานใบรับรองอิเล็กทรอนิกส์
                                    </span>
                                </small>
                                </IonText>
                            )}  

                            </IonCol>
                        </IonRow>

                        <IonRow>
                            {/* <IonCol className="ion-align-self-center"> */}
                            <IonCol>

                                {/* <div>{window.location.pathname.replace('/c/','')}</div> */}
                                <br/>
                                <div>ข้อมูลบนใบรับรองอิเล็กทรอนิกส์</div>
                                {/* <div>ข้อมูลข้างล่างนี้ใช้สำหรับแสดง</div>
                                <div>บนใบรับรองอิเล็กทรอนิกส์ของท่าน</div>  
                                <div>{referralCode}</div>
                                <div>{referralEmail}</div>*/}
                                <br/>
                                <IonItem>
                                    <IonLabel position="stacked">ชื่อ บริษัท/นิติบุคคล (ภาษาไทย)</IonLabel>
                                    <IonInput 
                                        value={nameTH} 
                                        {...register("nameTH")}
                                        onIonChange={(e) => setNameTH(e.detail.value!)}
                                    ></IonInput>
                                    
                                    {/* <p>{errors.nameTH?.message}</p> */}
                                </IonItem>
                                {errors && errors.nameTH && (
                                    <IonText color="danger">
                                    <small>
                                        <span role="alert" id={`nameTHError`}>
                                        {errors.nameTH?.message}
                                        </span>
                                    </small>
                                    </IonText>
                                )}

                                <IonItem>
                                    <IonLabel position="stacked">ชื่อ บริษัท/นิติบุคคล (ภาษาอังกฤษ)</IonLabel>
                                    <IonInput 
                                        value={nameEN}
                                        {...register("nameEN")}
                                        onIonChange={(e) => setNameEN(e.detail.value!)}
                                    ></IonInput>
                                </IonItem>
                                {errors && errors.nameEN && (
                                    <IonText color="danger">
                                    <small>
                                        <span role="alert" id={`nameENError`}>
                                        {errors.nameEN?.message}
                                        </span>
                                    </small>
                                    </IonText>
                                )}

                                <IonItem>
                                    <IonLabel position="stacked">เลขทะเบียนนิติบุคคล 13 หลัก</IonLabel>
                                    <IonInput 
                                        value={taxId}
                                        {...register("taxId")}
                                        onIonChange={(e) => setTaxId(e.detail.value!)}                                        
                                    ></IonInput>
                                </IonItem>
                                {errors && errors.taxId && (
                                    <IonText color="danger">
                                    <small>
                                        <span role="alert" id={`taxIdError`}>
                                        {errors.taxId?.message}
                                        </span>
                                    </small>
                                    </IonText>
                                )}

                                <IonItem>
                                    <IonLabel position="stacked">อีเมล (ส่วนมากจะใช้อีเมลกลาง แผนกบัญชี)</IonLabel>
                                    {/* <br/>
                                    <IonNote>อีเมลนี้จะแสดงบนทุกเอกสาร (ส่วนมากจะใช้อีเมลกลาง แผนกบัญชี)</IonNote> */}
                                    {/* <IonLabel position="stacked">อีเมลนี้จะแสดงบนทุกเอกสาร ที่มีการลงลายเซ็นดิจิทัล (ส่วนมากจะใช้อีเมลกลาง แผนกบัญชี)</IonLabel> */}
                                    <IonInput 
                                        value={email}
                                        {...register("email")}
                                        onIonChange={(e) => setEmail(e.detail.value!)}    
                                    ></IonInput>
                                </IonItem>
                                {/* <small>อีเมลนี้จะแสดงบนทุกเอกสาร (ส่วนมากจะใช้อีเมลกลาง แผนกบัญชี)</small> */}
                                {/* <br/> */}

                                {errors && errors.email && (
                                    
                                    <IonText color="danger">
                                    <small>
                                        <span role="alert" id={`emailError`}>
                                        {errors.email?.message}
                                        </span>
                                    </small>
                                    </IonText>
                                )}

                            </IonCol>
                        </IonRow>
                    </IonGrid>
      
                    
                </SwiperSlide>
                <SwiperSlide>
                    <IonGrid>
                        <IonRow>
                            <IonCol className="ion-align-self-center">

                                <div>ที่อยู่เจ้าของใบรับรองอิเล็กทรอนิกส์</div>
                                <br/>
                                <IonItem>
                                    <IonLabel position="stacked">ที่อยู่ตามหนังสือรับรองนิติบุคคล</IonLabel>
                                    <IonInput 
                                        value={address}
                                        {...register("address")}
                                        onIonChange={(e) => setAddress(e.detail.value!)}    
                                    />
                                </IonItem>
                                {errors && errors.address && (
                                    <IonText color="danger">
                                    <small>
                                        <span role="alert" id={`addressError`}>
                                        {errors.address?.message}
                                        </span>
                                    </small>
                                    </IonText>
                                )}

                                <IonItem>
                                    <IonLabel position="stacked">เบอร์โทรติดต่อ</IonLabel>
                                    <IonInput 
                                        value={phone}
                                        {...register("phone")}
                                        onIonChange={(e) => setPhone(e.detail.value!)}    
                                    />
                                </IonItem>
                                {errors && errors.phone && (
                                    <IonText color="danger">
                                    <small>
                                        <span role="alert" id={`phoneError`}>
                                        {errors.phone?.message}
                                        </span>
                                    </small>
                                    </IonText>
                                )}

                                <IonItem>
                                    <IonLabel position="stacked">โทรศัพท์มือถือ</IonLabel>
                                    <IonInput 
                                        value={mobile}
                                        {...register("mobile")}
                                        onIonChange={(e) => setMobile(e.detail.value!)}    
                                    />
                                </IonItem>
                                {errors && errors.mobile && (
                                    <IonText color="danger">
                                    <small>
                                        <span role="alert" id={`mobileError`}>
                                        {errors.mobile?.message}
                                        </span>
                                    </small>
                                    </IonText>
                                )}
                                
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </SwiperSlide>
                <SwiperSlide>
                    <IonGrid>
                        <IonRow>
                            <IonCol className="ion-align-self-center">

                                <div>รายละเอียดผู้ประสานงาน</div>
                                <small>ใช้สำหรับติดต่อประสานงานผู้ขอใบรับรองอิเล็กทรอนิกส์</small>
                                <br/>
                                <br/>
                            </IonCol>
                        </IonRow>

                        <IonRow>
                            <IonCol className="ion-align-self-center" size="4">

                                <IonItem>
                                    <IonLabel position="stacked">ชื่อ</IonLabel>
                                    <IonInput 
                                        value={firstName}
                                        {...register("firstName")}
                                        onIonChange={(e) => setFirstName(e.detail.value!)}    
                                    />
                                </IonItem>
                                {errors && errors.firstName && (
                                    <IonText color="danger">
                                    <small>
                                        <span role="alert" id={`firstNameError`}>
                                        {errors.firstName?.message}
                                        </span>
                                    </small>
                                    </IonText>
                                )}

                            </IonCol>

                            <IonCol className="ion-align-self-center" size="8">

                                <IonItem>
                                    <IonLabel position="stacked">นามสกุล</IonLabel>
                                    <IonInput 
                                        value={lastName}
                                        {...register("lastName")}
                                        onIonChange={(e) => setLastName(e.detail.value!)}    
                                    />
                                </IonItem>
                                {errors && errors.lastName && (
                                    <IonText color="danger">
                                    <small>
                                        <span role="alert" id={`lastNameError`}>
                                        {errors.lastName?.message}
                                        </span>
                                    </small>
                                    </IonText>
                                )}
                                
                            </IonCol>
                        </IonRow>

                        <IonRow>
                            <IonCol>

                                <IonItem>
                                    <IonLabel position="stacked">อีเมล</IonLabel>
                                    <IonInput 
                                        value={contactEmail}
                                        {...register("contactEmail")}
                                        onIonChange={(e) => setContactEmail(e.detail.value!)}    
                                    />
                                </IonItem>
                                {errors && errors.contactEmail && (
                                    <IonText color="danger">
                                    <small>
                                        <span role="alert" id={`contactEmailError`}>
                                        {errors.contactEmail?.message}
                                        </span>
                                    </small>
                                    </IonText>
                                )}

                                <IonItem>
                                    <IonLabel position="stacked">เบอร์โทรติดต่อ</IonLabel>
                                    <IonInput 
                                        value={contactMobile}
                                        {...register("contactMobile")}
                                        onIonChange={(e) => setContactMobile(e.detail.value!)}    
                                    />
                                </IonItem>
                                {errors && errors.contactMobile && (
                                    <IonText color="danger">
                                    <small>
                                        <span role="alert" id={`contactMobileError`}>
                                        {errors.contactMobile?.message}
                                        </span>
                                    </small>
                                    </IonText>
                                )}
                                
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </SwiperSlide>
                <SwiperSlide>
                    <IonGrid>
                        <IonRow>
                            <IonCol className="ion-align-self-center">
                            
                                <div>ข้อมูลบนใบรับรองอิเล็กทรอนิกส์</div>
                                <br/>
                            </IonCol>
                        </IonRow>

                        <IonRow>
                            <IonCol>

                                <IonItemDivider>อายุใบรับรองอิเล็กทรอนิกส์</IonItemDivider>
                                <IonItem lines="none">
                                    <IonText color="primary">
                                        {period} ปี
                                    </IonText>
                                </IonItem>

                                <IonItemDivider>ชื่อ บริษัท/นิติบุคคล (ภาษาไทย)</IonItemDivider>
                                <IonItem lines="none">
                                    <IonText color="primary">
                                        {nameTH}
                                    </IonText>
                                </IonItem>

                                <IonItemDivider>ชื่อ บริษัท/นิติบุคคล (ภาษาอังกฤษ)</IonItemDivider>
                                <IonItem lines="none">
                                    <IonText color="primary">
                                        {nameEN}
                                    </IonText>
                                </IonItem>

                                <IonItemDivider>เลขทะเบียนนิติบุคคล</IonItemDivider>
                                <IonItem lines="none">
                                    <IonText color="primary">
                                        {taxId}
                                    </IonText>
                                </IonItem>

                                <IonItemDivider>อีเมล</IonItemDivider>
                                <IonItem lines="none">
                                    <IonText color="primary">
                                        {email}
                                    </IonText>
                                </IonItem>

                            </IonCol>
                            
                        </IonRow>

                        <IonRow>
                            <IonCol className="ion-align-self-center">

                                <br/>
                                <div>ที่อยู่เจ้าของใบรับรองอิเล็กทรอนิกส์</div>
                                <br/>
                            </IonCol>
                        </IonRow>

                        <IonRow>
                            <IonCol>

                                <IonItemDivider>ที่อยู่ตามหนังสือรับรองนิติบุคคล</IonItemDivider>
                                <IonItem lines="none">
                                    <IonText color="primary">
                                        {address}
                                    </IonText>
                                </IonItem>

                                <IonItemDivider>เบอร์โทรติดต่อ</IonItemDivider>
                                <IonItem lines="none">
                                    <IonText color="primary">
                                        {phone}
                                    </IonText>
                                </IonItem>

                                <IonItemDivider>โทรศัพท์มือถือ</IonItemDivider>
                                <IonItem lines="none">
                                    <IonText color="primary">
                                        {mobile}
                                    </IonText>
                                </IonItem>

                            </IonCol>
                            
                        </IonRow>

                        <IonRow>
                            <IonCol className="ion-align-self-center">

                                <br/>
                                <div>รายละเอียดผู้ประสานงาน</div>
                                <br/>
                            </IonCol>
                        </IonRow>

                        <IonRow>
                            <IonCol>

                                <IonItemDivider>ชื่อ - นามสกุล</IonItemDivider>
                                <IonItem lines="none">
                                    <IonText color="primary">
                                        {firstName} {lastName}
                                    </IonText>
                                </IonItem>

                                <IonItemDivider>อีเมล</IonItemDivider>
                                <IonItem lines="none">
                                    <IonText color="primary">
                                        {contactEmail}
                                    </IonText>
                                </IonItem>

                                <IonItemDivider>เบอร์โทรติดต่อ</IonItemDivider>
                                <IonItem lines="none">
                                    <IonText color="primary">
                                        {contactMobile}
                                    </IonText>
                                </IonItem>

                            </IonCol>
                            
                        </IonRow>

                        
                    </IonGrid>
                </SwiperSlide>
                
            </Swiper>

            <IonToolbar class="footer">
            
            <IonRow>
                            <IonCol className="ion-align-self-center" size="3" style={{padding: 0}}>
                                <IonButton color="medium" onClick={() => { 
                                        // mySwiper.slidePrev() 
                                        let _page = page;
                                        if (_page > 0) {
                                            setNextText('ต่อไป');

                                            setPage(_page-1);
                                            mySwiper.slideTo(_page-1);
                                        }
                                        console.log('page:', _page+1);

                                        scrollToTop();
                                        
                                    }} 
                                    expand="full" 
                                    style={{padding: 0}}>กลับ</IonButton>
                            </IonCol>
                            <IonCol className="ion-align-self-center" size="9" style={{padding: 0}}>
                                <IonButton 

                                    onClick={async (e) => { 
                                        let _page = page;
                                        // if ((_page < 3) && isValid) {
/* 
                                        if ((_page < 3)) {
                                            setPage(_page+1);
                                            mySwiper.slideTo(_page+1);
                                        }
                                         */
                                        console.log('page:', _page+1);

                                        console.log('isValid:', isValid);
                                        console.log('errors:', errors);

                                        switch(_page) {
                                            case 0:
                                                if ((period > 0) && !errors.nameTH && !errors.nameEN && !errors.taxId && !errors.email) {
                                                    setPage(_page+1);
                                                    mySwiper.slideTo(_page+1);

                                                    scrollToTop();
                                                }
                                                console.log('case:', errors.period);
                                                break;
                                            case 1:
                                                if (!errors.address && !errors.phone && !errors.mobile) {
                                                    setPage(_page+1);
                                                    mySwiper.slideTo(_page+1);

                                                    scrollToTop();
                                                }
                                                break;
                                            case 2:
                                                if (errors && !errors.firstName && !errors.lastName && !errors.contactEmail && !errors.contactMobile) {
                                                    setNextText('ยืนยัน');

                                                    setPage(_page+1);
                                                    mySwiper.slideTo(_page+1);

                                                    scrollToTop();
                                                }
                                                break;
                                            case 3:

                                                // Add a new document with a generated id.
                                                const docRef = await addDoc(collection(db, "CertificateRequests"), {
                                                    period: period,
                                                    nameTH: nameTH,
                                                    nameEN: nameEN,
                                                    taxId: taxId,
                                                    email: email,
                                                    address: address,
                                                    phone: phone,
                                                    mobile: mobile,
                                                    firstName: firstName,
                                                    lastName: lastName,
                                                    contactEmail: contactEmail,
                                                    contactMobile: contactMobile,
                                                    // referralCode: null,
                                                    createdTime: serverTimestamp(),
                                                    updatedTime: serverTimestamp(),
                                                    status: 'new',
                                                    referralCode: referralCode
                                    
                                                });
                                                console.log("Document written with ID: ", docRef.id);
                                        
                                                history.push('/forms/certificate-requests/submitted');
                                                break;
                                            default:
                                        }
                                        // setPage(page+1);
                                        // mySwiper.slideNext();
                                        // mySwiper.slideTo(page+1);
                                        // handleSubmit(submitForm);
                                        // submitForm(e);
                                        // handleSubmit((d) => console.log('handleSubmit:', d))
                                    }}
                                    
                                    type="submit"
                                    expand="full" 
                                    style={{padding: 0}}>{nextText}</IonButton>
                            </IonCol>
                        </IonRow>
                </IonToolbar>

            </form>
            
        </IonContent>
{/* 
        <IonFooter>
                <IonToolbar>
                    <IonGrid>
                        <IonRow>
                            <IonCol className="ion-align-self-center" size="3" style={{padding: 0}}>
                                <IonButton color="medium" onClick={() => { 
                                        // mySwiper.slidePrev() 
                                        let _page = page;
                                        if (_page > 0) {
                                            setPage(_page-1);
                                            mySwiper.slideTo(_page-1);
                                        }
                                        console.log('page:', _page+1);
                                        
                                    }} 
                                    expand="full" 
                                    style={{padding: 0}}>กลับ</IonButton>
                            </IonCol>
                            <IonCol className="ion-align-self-center" size="9" style={{padding: 0}}>
                                <IonButton 

                                    onClick={(e) => { 
                                        let _page = page;
                                        if ((_page < 3)) {
                                            setPage(_page+1);
                                            mySwiper.slideTo(_page+1);
                                        }
                                        console.log('page:', _page+1);
                                        // setPage(page+1);
                                        // mySwiper.slideNext();
                                        // mySwiper.slideTo(page+1);
                                        // handleSubmit(submitForm);
                                        // submitForm(e);
                                        handleSubmit((d) => console.log('handleSubmit:', d))
                                    }}
                                    
                                    type="submit"
                                    expand="full" 
                                    style={{padding: 0}}>ต่อไป</IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonToolbar>
            </IonFooter>

             */}
      </IonPage>
  );
};

export default CertificateRequestForm;
