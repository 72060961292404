import {
    IonContent,
    IonPage,
    IonText,
    IonInput,
    IonButton,
    IonCheckbox,
    IonItem,
    IonLabel,
    IonToolbar,
    IonTitle,
    IonHeader,
    IonToast,
    useIonToast, 
    IonLoading,
    IonTabs, 
    IonTabBar, 
    IonTabButton, 
    IonIcon, 
    IonBadge,
    IonRouterOutlet,
    IonCard,
    IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent
  } from "@ionic/react";
import React, { useState, useEffect } from 'react';
import { Link, useHistory, Route, Redirect } from 'react-router-dom';
import { loginUser, logoutUser } from '../firebaseConfig';
import { home, personCircle, list } from 'ionicons/icons';
import { IonReactRouter } from '@ionic/react-router';
import { db } from '../firebaseConfig';
import { collection, getDocs } from "firebase/firestore";
import { pin, wifi, wine, warning, walk } from 'ionicons/icons';

const Settings = () => <IonPage>Settings</IonPage>;

const CertificateRequestsSubmitted: React.FC = () => {
    // const [email, setEmail] = useState('');
    // const [password, setPassword] = useState('');
    const [present, dismiss] = useIonToast();
    const [loading, setLoading] = useState<boolean>(false);
    const [certRequests, setCertRequests] = useState<any[]>([]);

    // const username = useSelector((state: any) => state.user.email);


    const history = useHistory();

    async function logout() {
        await logoutUser();
        history.replace('/');
    }
 
    useEffect(() => {

        console.log('call initial useEffect');
       
        const certRequestsCollectionRef = collection(db, 'CertificateRequests');

        // certRequestsCollectionRef.get

        const getCertRequests = async () => {
            const querySnapshot = await getDocs(certRequestsCollectionRef);
            console.log(querySnapshot);

            const data = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
            console.log(data);

            setCertRequests(data);
        }

        getCertRequests();
 
    }, [])

    return (
    <IonPage>
        <IonHeader>
            <IonToolbar>
                <IonTitle>Leceipt</IonTitle>
            </IonToolbar>
        </IonHeader>
        {/* <IonLoading message='กำลังดำเนินการ...' duration={0} isOpen={loading} /> */}
        <IonContent>

            <IonText color="primary">
                <h1 style={{textAlign: 'center', marginTop: 35}}>สำเร็จ</h1>
            </IonText>

            <IonText>
                <h4 style={{textAlign: 'center', marginTop: 30}}>เจ้าหน้าที่จะนำข้อมูลที่ท่านกรอก</h4>
                <h4 style={{textAlign: 'center', marginTop: 20}}>ไปสร้างใบคำขอใบรับรองอิเล็กทรอนิกส์</h4>
                <h4 style={{textAlign: 'center', marginTop: 20}}>แล้วจัดส่งให้ท่านลงนาม</h4>
                <br/>
            </IonText>


            <IonText>
                <h4 style={{textAlign: 'center', marginTop: 20}}>หากต้องการสอบถาม</h4>
            </IonText>

            <IonText>
                <h5 style={{textAlign: 'center', marginTop: 20}}>โทร. 082-579-8555 หรือ Line Id: @Leceipt</h5>
                <h5 style={{textAlign: 'center', marginTop: 20}}>เวลาทำการ 8:30 - 17:30 น.</h5>
                <br/>
            </IonText>


            <IonText>
                <h4 style={{textAlign: 'center', marginTop: 20}}>ทดลองใช้งานโปรแกรม Leceipt</h4>
            </IonText>

            <IonText>
                <div style={{textAlign: 'center', marginTop: 20}}>(e-Tax Invoice & e-Receipt)</div>
                <h5 style={{textAlign: 'center', marginTop: 20}}><a href="https://www.leceipt.com" target="_blank">www.leceipt.com</a></h5>
            </IonText>


        </IonContent>
        
        
      </IonPage>
  );
};

export default CertificateRequestsSubmitted;
