import {
    IonContent,
    IonPage,
    IonInput,
    IonButton,
    IonToolbar,
    IonTitle,
    IonHeader,
    IonToast,
    useIonToast, 
    IonLoading,
    IonText,
    IonItem,
    IonLabel,
    IonImg
  } from "@ionic/react";
import React, { useState, useEffect, useContext } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { loginUser } from '../firebaseConfig';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
// import Input, { InputProps } from "../components/Input";
import { object, string, number } from "yup";
import { useAuth } from "../auth";

import LeceiptLogo from '../assets/images/leceipt-logo-center.png';

const Login: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [present, dismiss] = useIonToast();
    const [loading, setLoading] = useState<boolean>(false);
    const history = useHistory();

    const { loggedIn } = useAuth();

    const validationSchema = object().shape({
        email: string().required('กรุณากรอกอีเมล').email(),      
        password: string().required('กรุณากรอกรหัสผ่านอย่างน้อย 8 ตัว').min(8),        
    });
    /* 
    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });
 */
    const { register, handleSubmit, formState:{ isValid, errors }, control } = useForm({
        mode: 'all',
        // reValidateMode: 'onChange',
        resolver: yupResolver(validationSchema)
    });
    /* 
    const formFields: InputProps[] = [
        {
            name: "email",
            component: <IonInput type="email" />,
            label: "อีเมล",
        },      
        {
            name: "password",
            component: <IonInput type="password" clearOnEdit={false} />,
            label: "รหัสผ่าน",
        },        
    ];
 */
    const login = async (data: any) => {
        // console.log(email, password);
        console.log(data);
        console.log("login a new user account with: ", data);

        setLoading(true);

        const res: any = await loginUser(data.email, data.password);
        // console.log(res);
        // console.log(`${res ? 'Login success' : 'Login failed'}`);
        if (res) {
            // dispatch(setUserState(res.user.email));
            history.replace('/my/link');
            present('เข้าสู่ระบบสำเร็จ', 2000);
        } else {
            present('อีเมลหรือรหัสผ่านไม่ถูกต้อง', 2000);
        }

        setLoading(false);

    }

    console.log('Login loggedIn:', loggedIn);

    if (loggedIn) {
        return <Redirect to='/my/link' />;
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>เข้าสู่ระบบ Leceipt Affiliate</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonLoading message='กำลังดำเนินการ...' duration={0} isOpen={loading} />
            <IonContent>
            <div className="ion-padding">
                <form className="ion-padding" onSubmit={handleSubmit(login)}>
                {/* 
                <IonText color="muted">
                <h2>เข้าสู่ระบบ Leceipt Affiliate</h2>
                </IonText>
    
                <form className="ion-padding" onSubmit={handleSubmit(login)}>
                    {formFields.map((field, index) => (
                        <Input {...field} control={control} key={index} errors={errors}/>
                    ))}
    {/* 
                    <IonItem>
                        <IonLabel position="floating">Username</IonLabel>
                        <IonInput onIonChange={(e: any) => setEmail(e.target.value)} />
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating">Password</IonLabel>
                        <IonInput type="password" onIonChange={(e: any) => setPassword(e.target.value)} />
                    </IonItem>
                    

                    <IonItem lines="none">
                        <IonLabel>Remember me</IonLabel>
                        <IonCheckbox defaultChecked={true} slot="start" />
                    </IonItem>
                    */}
                    <br/>
                    <div style={{paddingLeft: 30}}>
                        <IonImg  src={LeceiptLogo} style={{ height: 75 }} />
                    </div>
                    {/* <img  src="https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png" /> */}
                    <br/>
                    <br/>
                    <IonItem>
                        <IonLabel position="floating">อีเมล</IonLabel>
                        <IonInput 
                            value={email}
                            {...register("email")}
                            onIonChange={(e) => setEmail(e.detail.value!)}    
                        />
                    </IonItem>
                    
                    {errors && errors.email && (
                        <IonText color="danger">
                        <small>
                            <span role="alert" id={`emailError`}>
                            {errors.email?.message}
                            </span>
                        </small>
                        </IonText>
                    )}

                    <br/>

                    <IonItem>
                        <IonLabel position="floating">รหัสผ่าน</IonLabel>
                        <IonInput 
                            value={password}
                            {...register("password")}
                            onIonChange={(e) => setPassword(e.detail.value!)}    
                            type="password"
                        />
                    </IonItem>

                    {errors && errors.password && (
                        <IonText color="danger">
                        <small>
                            <span role="alert" id={`passwordError`}>
                            {errors.password?.message}
                            </span>
                        </small>
                        </IonText>
                    )}
                    
                    <br/>
                    <br/>

                    <IonButton className="ion-margin-top" type="submit" expand="block">
                        เข้าสู่ระบบ
                    </IonButton>
                    </form>
                    {/* <br/> */}
                
                    <p style={{textAlign: 'center'}}>
                        ยังไม่มีบัญชี <Link to='/register'>ลงทะเบียนที่นี่</Link>
                    </p>
            </div>
            </IonContent>
        </IonPage>
    );
};

export default Login;
