import {
    IonContent,
    IonPage,
    IonText,
    IonInput,
    IonButton,
    IonCheckbox,
    IonItem,
    IonLabel,
    useIonToast,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonLoading,
    IonImg
} from "@ionic/react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, number } from "yup";
import { registerUser } from "../firebaseConfig";
import { Link, Redirect, useHistory } from "react-router-dom";
import { useAuth } from "../auth";

import './Register.css';

import LeceiptLogo from '../assets/images/leceipt-logo-center.png';

const Register: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [fullName, setFullName] = useState('');
    const [mobile, setMobile] = useState('');
    const [acceptTerms, setAcceptTerms] = useState(false);

    const [present, dismiss] = useIonToast();
    const [loading, setLoading] = useState<boolean>(false);

    const { loggedIn } = useAuth();

    const history = useHistory();

    const validationSchema = object().shape({
        email: string().required("กรุณากรอกอีเมล").email(),
        password: string().required("กรุณากรอกรหัสผ่านอย่างน้อย 8 ตัว").min(8, "กรุณากรอกรหัสผ่านอย่างน้อย 8 ตัว"),
        fullName: string().required("กรุณากรอกชื่อ นามสกุล").min(5).max(32),
        mobile: string().required(
            "กรุณากรอกเบอร์มือถือเฉพาะตัวเลข เช่น 0812345555"
        ).min(10, "กรุณากรอกเบอร์มือถือเฉพาะตัวเลข เช่น 0812345555").max(10, "กรุณากรอกเบอร์มือถือเฉพาะตัวเลข เช่น 0812345555"),
    });
    /* 
    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });
 */
    const {
        register,
        handleSubmit,
        formState: { isValid, errors },
        control,
    } = useForm({
        mode: "all",
        // reValidateMode: 'onChange',
        resolver: yupResolver(validationSchema),
    });
    /* 
    const formFields: InputProps[] = [
      {
        name: "email",
        component: <IonInput type="email" />,
        label: "อีเมล",
      },      
      {
        name: "password",
        component: <IonInput type="password" clearOnEdit={false} />,
        label: "รหัสผ่าน",
      },
      {
        name: "fullName",
        label: "ชื่อ นามสกุล",
      },
      {
        name: "mobile",
        label: "เบอร์มือถือ",
      },
    ];
   */
    const onRegister = async (data: any) => {
        console.log("creating a new user account with: ", data);

        if (acceptTerms) {

            setLoading(true);

            const res = await registerUser(data.email, data.password, data.fullName, data.mobile);

            if (res) {
                present("ลงทะเบียนสำเร็จ", 2000);
            } else {
                present("ลงทะเบียนไม่สำเร็จ", 2000);
            }

            setLoading(false);

        }
    };

    if (loggedIn) {
        return <Redirect to="/my/link" />;
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>สร้างบัญชี Leceipt Affiliate</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonLoading
                message="กำลังดำเนินการ..."
                duration={0}
                isOpen={loading}
            />
            <IonContent>
                <div className="ion-padding">
                    {/* 
            <IonText color="muted">
              <h2>สร้างบัญชี Leceipt Affiliate</h2>
            </IonText>


   */}

                <form onSubmit={handleSubmit(onRegister)}>

                    {/* <br/> */}
                    <br/>
                    <div style={{paddingLeft: 30}}>
                        <IonImg  src={LeceiptLogo} style={{ height: 75 }} />
                    </div>
                    {/* <img  src="https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png" /> */}
                    <br/>
                    <br/>
                        {/*     
              {formFields.map((field, index) => (
                <Input {...field} control={control} key={index} errors={errors}/>
              ))}
                <br/> */}

                        <IonItem>
                            <IonLabel position="floating">อีเมล</IonLabel>
                            <IonInput 
                                value={email}
                                {...register("email")}
                                onIonChange={(e) => setEmail(e.detail.value!)}    
                            />
                        </IonItem>
                        
                        {errors && errors.email && (
                            <IonText color="danger">
                            <small>
                                <span role="alert" id={`emailError`}>
                                {errors.email?.message}
                                </span>
                            </small>
                            </IonText>
                        )}

                        <IonItem>
                            <IonLabel position="floating">รหัสผ่าน</IonLabel>
                            <IonInput 
                                value={password}
                                {...register("password")}
                                onIonChange={(e) => setPassword(e.detail.value!)}    
                                type="password"
                            />
                        </IonItem>

                        {errors && errors.password && (
                            <IonText color="danger">
                            <small>
                                <span role="alert" id={`passwordError`}>
                                {errors.password?.message}
                                </span>
                            </small>
                            </IonText>
                        )}
                        <br />
                        <br />

                        <IonItem>
                            <IonLabel position="floating">ชื่อ นามสกุล</IonLabel>
                            <IonInput 
                                value={fullName}
                                {...register("fullName")}
                                onIonChange={(e) => setFullName(e.detail.value!)}    
                            />
                        </IonItem>
                        
                        {errors && errors.fullName && (
                            <IonText color="danger">
                            <small>
                                <span role="alert" id={`fullNameError`}>
                                {errors.fullName?.message}
                                </span>
                            </small>
                            </IonText>
                        )}

                        <IonItem>
                            <IonLabel position="floating">เบอร์มือถือ</IonLabel>
                            <IonInput 
                                value={mobile}
                                {...register("mobile")}
                                onIonChange={(e) => setMobile(e.detail.value!)}    
                            />
                        </IonItem>
                        
                        {errors && errors.mobile && (
                            <IonText color="danger">
                            <small>
                                <span role="alert" id={`mobileError`}>
                                {errors.mobile?.message}
                                </span>
                            </small>
                            </IonText>
                        )}

                        <br />

                        <IonItem lines="none">
                            <IonLabel>
                                <a 
                                    // target="_blank" 
                                    className="otherPage"
                                    onClick={() => { 
                                        history.push('/terms-conditions');                    
                                    }}
                                >
                                    ยอมรับเงื่อนไขและข้อตกลง
                                </a>
                                
                            </IonLabel>
                            <IonCheckbox 
                                slot="start"
                                value={acceptTerms}
                                onIonChange={
                                    (e) => {
                                        setAcceptTerms(e.detail.checked);

                                        console.log(e.detail.checked);
                                
                                    }}
                            />
                        </IonItem>

                        {!acceptTerms && (
                            // <div style={{textAlign: 'center', color: '#EB445A' }}>
                            <IonText color="danger">
                                <small>
                                    <span role="alert" id={`acceptTermsError`}>
                                        กรุณาเลือกยอมรับเงื่อนไขและข้อตกลง
                                    </span>
                                </small>
                            </IonText>
                            // </div>
                        )}
                        <br />
                        <br />
                        <br />
                        <IonButton
                            expand="block"
                            type="submit"
                            className="ion-margin-top"
                        >
                            ลงทะเบียน
                        </IonButton>
                    </form>
                    <br />

                    <p style={{ textAlign: "center" }}>
                        สร้างบัญชีแล้ว{" "}
                        <Link to="/login">เข้าสู่ระบบที่นี่</Link>
                    </p>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Register;
